.sr-footer-logo{
  display: none;
}


.sr-block{
  background-color: $hotboys-yellow !important;
}

.sr-product-details{
  background-color: $hotboys-off-white;
}



.sr-default .sr-buy-widget .sr-add {
  color: $hotboys-blue !important;
}


.sr-block {
  color: $hotboys-blue !important;
}

//
// .sr-item{
//   width: 25em !important;
// }
