.text-center{
    text-align: center;
}



//
@media (min-width: 992px) {

  #main_header_img{

    max-width: 75%;
  }

}


@media (min-width: 1025px) {

  #main_header_img{

    max-width:60%;
  }

}



@media (max-width: 641px) {
  #main_header_img{

    max-width: 100%;
  }


}





.btn-red-color{
  background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #da291c), color-stop(1, #000000));
	background:-moz-linear-gradient(top, #da291c 80%, #000000 100%);
	background:-webkit-linear-gradient(top, #da291c 80%, #000000 100%);
	background:-o-linear-gradient(top, #da291c 80%, #000000 100%);
	background:-ms-linear-gradient(top, #da291c 80%, #000000 100%);
	background:linear-gradient(to bottom, #da291c 80%, #000000 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#da291c', endColorstr='#000000',GradientType=0);
	background-color:#da291c;
	-moz-border-radius:3px;
	-webkit-border-radius:3px;
	border-radius:3px;
	border:1px solid #0b0e07;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family: "Times New Roman", Times, serif;

}

.btn-red {
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #da291c), color-stop(1, #000000));
	background:-moz-linear-gradient(top, #da291c 80%, #000000 100%);
	background:-webkit-linear-gradient(top, #da291c 80%, #000000 100%);
	background:-o-linear-gradient(top, #da291c 80%, #000000 100%);
	background:-ms-linear-gradient(top, #da291c 80%, #000000 100%);
	background:linear-gradient(to bottom, #da291c 80%, #000000 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#da291c', endColorstr='#000000',GradientType=0);
	background-color:#da291c;
	-moz-border-radius:3px;
	-webkit-border-radius:3px;
	border-radius:3px;
	border:1px solid #0b0e07;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family: "Times New Roman", Times, serif;
	font-size:15px;
	padding:20px 59px;
	text-decoration:none;
}

.btn-red:hover {
  color:#ffffff;
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #000000), color-stop(1, #da291c));
	background:-moz-linear-gradient(top, #000000 40%, #da291c 100%);
	background:-webkit-linear-gradient(top, #000000 40%, #da291c 100%);
	background:-o-linear-gradient(top, #000000 40%, #da291c 100%);
	background:-ms-linear-gradient(top, #000000 40%, #da291c 100%);
	background:linear-gradient(to bottom, #000000 40%, #da291c 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#da291c',GradientType=0);
	background-color:#000000;
}
.btn-red:active {
	position:relative;
	top:1px;
}

.hb-bg-red{
  background-color: $hotboys-red;
}

.hb-bg-blue{
  background-color: $hotboys-blue;
}

.hb-bg-white{
  background-color: $hotboys-off-white;
}
.hb-bg-yellow{
  background-color: $hotboys-yellow !important;
}


.hb-red{
  color: $hotboys-red;
}

.hb-blue{
  color: $hotboys-blue;
}

.hb-white{
  color: $hotboys-off-white;
}

.hb-yellow{
  color: $hotboys-yellow !important;
}

.half-opacity{
  opacity: 0.8;
}

.bg-half-opacity {
  background-color: #ffffffa1 !important; /* Adjust the color and opacity as needed */
}

.zoom {
  transition: transform .2s; /* Animation */
}

.zoom:hover {
  transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}


.fire-icon {
  position: absolute; display: block;
  // border: 2px solid red; border-radius: 50px;
  height: 100px; width: 100px;
  background-image: url("images/hotboys_transparent.png");
  // background-size: 120%;
  background-repeat: no-repeat;
  // transform: scale(1);
  pointer-events:none;


}

.hb-db-border{
  border-style: groove;
  border-width: 5px;
  // border-style: dashed;
  // border: 4px;
}

.hb-red-border{
  border-color: $hotboys-red;
}

.hb-blue-border{
  border-color: $hotboys-blue;
}

.hb-white-border{
  border-color: $hotboys-off-white;
}


.spice-gradient{
  background: rgb(246,242,244);
  background: -moz-linear-gradient(90deg,  $hotboys-yellow 0%, $hotboys-red 38%, $hotboys-blue 100%);
  background: -webkit-linear-gradient(90deg,  $hotboys-yellow 0%, $hotboys-red 38%, $hotboys-blue 100%);
  background: linear-gradient(90deg,  $hotboys-yellow 0%, $hotboys-red 38%, $hotboys-blue 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6f2f4",endColorstr="#da291c",GradientType=1);
}


@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}


.animation-spin{
  animation-name: spin;
  animation-duration: 10000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.text-shadow{
  text-shadow: -2px 4px rgba(63,107,169, 0.8);
  font-weight: 400;
}



.nudge{
  animation: nudge 9s linear infinite alternate;

}


@keyframes nudge {
  0%, 100% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(10%, -12%);
  }

  50% {
    transform: translate(0, 0);
  }

  75% {
    transform: translate(-12%, 10%);
  }
}

.card-img-top {
    width: 100%;
    height: 35vh;
    object-fit: cover;
}

.no-border{
  border: none !important;
}





div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  color: $hotboys-blue;
  font-size: 20px;
}


@-webkit-keyframes flasher{
  from,
  50%,
  to {
    background-color: $hotboys-yellow;
  }

  25%,
  75% {
    background-color: black;
  }
}

@keyframes flasher{
  from,
  50%,
  to {
    background-color: $hotboys-yellow;
  }

  25%,
  75% {
    background-color: black;
  }
}

.color-flash {
  -webkit-animation-name: flasher;
  animation-name: flasher;
}



.color-change {
  -webkit-animation-name: color-change;
  animation-name: color-change;
  -webkit-animation-name: color-change; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
}


@keyframes color-change
 {
   0%   {background: red;}
   25%  {background: yellow;}
   50%  {background: blue;}
   75%  {background: green;}
   100% {background: red;}
 }

 @-webkit-keyframes color-change
 {
   0%   {background: red;}
   25%  {background: yellow;}
   50%  {background: blue;}
   75%  {background: green;}
   100% {background: red;}
 }

.footer-bg{
  background-image: url("images/HOTBOYS_LOCKUP(onecolor).png");
  height: 20em;
  width: 100%;
  background-repeat: space;
  background-size: 200px;
  background-clip: content-box;
}

.modal-header{
  border: none;
}

.modal-body{
  border: none;
}


.card-deck {
  display:flex;
  flex-wrap: wrap;
}
.float{
  // position:fixed;
  width: 4.5em;
  height: 4.5em;
  // top:40px;
  // left:40px;
  // background-color:#0C9;

  // z-index: 100;
}


// @media (min-width: 768px) {
//   // .float{
//   // 	// position:fixed;
//   // 	width:40px;
//   // 	height:40px;
//   // 	// top:40px;
//   // 	// left:40px;
//   // 	// background-color:#0C9;
//   //
//   //   z-index: 100;
//   // }
//
// }


// a img:last-child {
//   display: none;
// }
// a:hover img:last-child {
//   display: block;
// }
// a:hover img:first-child {
//   display: none;
// }
